import {getFormattedMoney, getPaidPlanPercentDiscount, getYearMonthDay} from '@wix/wix-events-commons-statics'
import {getCheckoutSummary, getTaxLabel} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import * as React from 'react'
import {OrderDetailsProps} from '.'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {ORDER_DETAILS} from '../../config/data-hooks'
import * as DH from '../../config/data-hooks'
import {Divider} from '../styled-components/divider'
import {DottedDivider} from '../styled-components/dotted-divider'
import {EventText} from '../styled-components/event-text'
import * as s from './order-details.scss'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{t('order.header.quantity')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.total')}</EventText>
    </div>
  </div>
)

const Item = ({item}) => (
  <div className={s.row}>
    <div className={s.colTicket}>
      <EventText>{item.name}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{getFormattedMoney(item.price)}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{item.quantity}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{getFormattedMoney(item.total)}</EventText>
    </div>
  </div>
)

export const Summary = ({invoice, t}) => {
  const {subtotal, discount, paidPlanDiscount, tax, total, addedFee} = getCheckoutSummary(invoice)
  const taxLabel = getTaxLabel(invoice)

  return (
    <div className={s.summary}>
      {subtotal && (
        <>
          <div className={s.subtotals} data-hook={DH.SUBTOTALS_CONTAINER}>
            <Price label={t('order.subtotal')} value={subtotal} />
            <Price
              label={t('order.paid-plan', {discount: getPaidPlanPercentDiscount(invoice)})}
              value={paidPlanDiscount}
            />
            <Price label={discount ? `${t('order.coupon.code')}${invoice.discount.code}` : null} value={discount} />
            <Price label={taxLabel} value={tax} dataHook={DH.TAX} />
            <Price label={t('order.serviceFee')} value={addedFee} />
          </div>
          <Divider />
        </>
      )}
      <div className={s.totals}>
        <div>
          <EventText large>{t('order.total')}</EventText>
        </div>
        <div>
          <EventText large>{total}</EventText>
        </div>
      </div>
    </div>
  )
}

const Price = ({label, value, dataHook = null}) => {
  if (!value) {
    return null
  }

  return (
    <div className={s.subtotalsRow} data-hook={dataHook}>
      <EventText>{label}</EventText>
      <EventText>{value}</EventText>
    </div>
  )
}

export const OrderDetails = ({order, t, timezone, locale}: OrderDetailsProps) => {
  const {orderNumber, created, invoice} = order
  return (
    <div className={s.root} data-hook={ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <BrowserOnly>
            <EventText>
              {t('order.date', {
                date: getYearMonthDay(created, timezone, locale),
              })}
            </EventText>
          </BrowserOnly>
        </div>
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {invoice.items.map(item => (
          <Item key={item.id} item={item} />
        ))}
        <Divider />
        <Summary invoice={invoice} t={t} />
      </div>
    </div>
  )
}
