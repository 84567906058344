export enum MembersSettings {
  noUpcomingEventsLinkPageId = 'noUpcomingEventsLinkPageId',
}

export enum MembersTexts {
  pageTitle = 'pageTitle',
  description = 'description',
  upcomingEventsTab = 'upcomingEventsTab',
  pastEventsTab = 'pastEventsTab',
  noUpcomingEventsLink = 'noUpcomingEventsLink',
}

export interface MembersComponentConfig {
  id: string
  settings: {
    [MembersSettings.noUpcomingEventsLinkPageId]?: string
    texts: {
      [key in MembersTexts]?: string
    },
  }
}

export enum MembersSettingsEvents {
  SETTINGS = 'SETTINGS',
  OPEN_DETAILS = 'OPEN_DETAILS',
  OPEN_NO_EVENTS = 'OPEN_NO_EVENTS',
  OPEN_DEFAULT = 'OPEN_DEFAULT',
}
