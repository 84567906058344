import * as React from 'react'
import {HeaderProps} from '.'
import {APP_HEADER_DESCRIPTION, APP_HEADER_TITLE} from '../../config/data-hooks'
import {Description} from '../styled-components/description'
import {PageTitle} from '../styled-components/page-title'
import s from './header.scss'

export const Header = ({title, subtitle}: HeaderProps) => {
  return (
    <div className={s.root}>
      <div className={s.title}>
        <PageTitle data-hook={APP_HEADER_TITLE}>{title}</PageTitle>
      </div>
      <div className={s.subtitle}>
        <Description data-hook={APP_HEADER_DESCRIPTION}>{subtitle}</Description>
      </div>
    </div>
  )
}
